<template>
  <SuiModal :open="open" @changed="$emit('changed', false)" size="mini">
    <div class="header">
      {{ $gettext('Leave this meeting') }}
    </div>
    <div class="actions">
      <button @click="onCancel" class="ui button">
        {{ $gettext('Cancel') }}
      </button>
      <button @click="onExit" class="ui red button">
        {{ $gettext('Leave Meeting') }}
      </button>
    </div>
  </SuiModal>
</template>

<script>
  import useOpenTokHelper from '../use/opentok-helper'
  import router from '../router'

  export default {
    name: 'ExitModal',
    model: {
      prop: 'open',
      event: 'changed',
    },
    props: {
      open: {
        type: Boolean
      },
      displayName: {
        default: '',
        type: String,
      },
      meetingID: {
        required: true,
        type: String,
      },
    },
    setup(props, {emit, root}) {
      const {
        disconnect,
      } = useOpenTokHelper()

      function onCancel() {
        emit('changed', false)
      }

      function onExit() {
        disconnect()

        const query = {
          meetingID: props.meetingID,
          displayName: props.displayName
        }

        router.replace({name: 'exit', query})
      }

      return {
        onCancel,
        onExit,
      }
    },
  }
</script>

<style scoped>

</style>

export const isDevelopment = process.env.NODE_ENV === 'development'
export const isNotDevelopment = process.env.NODE_ENV && process.env.NODE_ENV !== 'development'
export const isProduction = process.env.NODE_ENV === 'production' && !process.env.IS_E2E
export const isNotProduction = process.env.NODE_ENV && process.env.NODE_ENV !== 'production' && !process.env.IS_E2E
export const isTest = process.env.NODE_ENV === 'test'
export const isNotTest = process.env.NODE_ENV && process.env.NODE_ENV !== 'test'

// Public: True if the app is RUNNING in Github Action.
export const isCI = process && process.env && process.env.CI

export const currentCommitHash = process.env.CURRENT_COMMIT_HASH
export const appVersion = process.env.APP_VERSION

<template>
  <div class="wrapper">
    <h1 class="ui header">{{ $t('You have left the meeting') }}</h1>
    <template v-if="!redirectURL">
      <p>{{ $t('Thank you for attending the meeting.') }}</p>
      <p
        v-html="
          $t('Please {linkOpen}close{linkClose} this page.', {
            linkOpen: `<a href='https://pitcher.com/'>`,
            linkClose: '</a>',
          })
        "
      />
    </template>
    <div v-else>
      <p>{{ $t('You will be redirected in {countdown}s.', { countdown }) }}</p>
      <a :href="redirectURL" class="ui button redirect-button" :style="buttonStyle">{{ $t('CONTINUE') }}</a>
    </div>
  </div>
</template>

<script>
import useParticipantStatus from '@/use/participant-status'
import useMeeting from '@/use/meeting'
import { computed, onMounted, ref } from '@vue/composition-api'
import { isColorLight } from '@/colors'

const REDIRECT_TIME = 3

export default {
  name: 'Exit',
  props: {
    displayName: {
      default: '',
      type: String,
    },
    meetingID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { disconnect, participantKey } = useParticipantStatus()
    const { meeting } = useMeeting()
    const countdown = ref(REDIRECT_TIME)

    const redirectURL = computed(() => {
      if (!meeting.value.redirectURL) {
        return 'https://www.pitcher.com'
      }

      const url = new URL(meeting.value.redirectURL)

      url.searchParams.append('pID', participantKey.value)
      url.searchParams.append('mID', props.meetingID)

      if (props.displayName) {
        url.searchParams.append('pName', props.displayName)
      }

      return url.toString()
    })

    const buttonStyle = computed(() => ({
      backgroundColor: meeting.value.uiColorBack,
      color: !isColorLight(meeting.value.uiColorBack) ? '#ffffff' : null,
    }))

    onMounted(async () => {
      await disconnect()

      if (redirectURL.value) {

        const counter = setInterval(() => {
          countdown.value--
          if (countdown.value === 0) {
            clearInterval(counter)
            window.location.href = redirectURL.value
          }
        }, 1000);
      }
    })

    return {
      redirectURL,
      countdown,
      buttonStyle,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header {
  font-weight: 600;
}

.redirect-button {
  margin: 0 0 1em;
}
</style>

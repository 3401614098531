import * as Sentry from '@sentry/browser'
import axios from 'axios'
import OT from '@opentok/client'
import { computed, reactive, toRefs, watch } from '@vue/composition-api'
import { participantStatusDatabaseRef } from '@/use/participant-status'

const state = reactive({
  connected: false,
  connections: [],
  error: null,
  publishers: {
    camera: {
      active: false,
      audio: true,
      enabled: false,
      error: null,
      name: '',
      object: null,
      video: false,
    },
    screen: {
      active: false,
      enabled: false,
      error: null,
      object: null,
    },
  },
  session: null,
  streams: [],
})

watch(() => state.publishers.camera.audio, (val) => {
  participantStatusDatabaseRef.value?.update({
    audio: val
  })
},{ immediate: true })

watch(() => state.publishers.camera.video, (val) => {
  participantStatusDatabaseRef.value?.update({
    video: val
  })
},{ immediate: true })

const streamViews = computed(() => {
  if (!state.connected) {
    return []
  }

  const sortedStreams = sortStreams(state.streams)
  const views = sortedStreams.map(toStreamView)

  if (state.publishers.camera.active) {
    const hostCameraIndex = sortedStreams.findIndex(s =>
        s.videoType == 'camera' && s.connection.data.meetingRoles.includes('host')
    )

    views.splice(hostCameraIndex + 1, 0, {
      id: 'camera-publisher',
      class: ['camera camera-publisher'],
    })
  }

  if (state.publishers.screen.active) {
    views.push({
      id: 'screen-publisher',
      class: ['screen screen-publisher']
    })
  }

  return views
})

function sortStreams(streams) {
  return streams.sort((a, b) => {
    if (toStreamPriority(a) > toStreamPriority(b)) {
      return -1
    }

    if (toStreamPriority(a) < toStreamPriority(b)) {
      return 1
    }

    return 0
  })
}

function toStreamPriority(stream) {
  if (stream.videoType === 'camera') {
    const streamRoles = stream.connection.data.meetingRoles

    if (streamRoles.includes('presenter')) {
      return 5
    } else if (streamRoles.includes('host')) {
      return 4
    } else if (stream.hasAudio === true && stream.hasVideo === true) {
      return 3
    } else if (stream.hasAudio === true) {
      return 2
    } else if (stream.hasVideo === true) {
      return 1
    }
  } else if (stream.videoType === 'screen') {
    // The latest stream will have the lowest priority and will be rendered as the last one in the UI.
    // As a result it will be on top of other streams.
    return 0 - stream.creationTime
  }

  return 0
}

function toStreamView(stream) {
  // Default to the camera value for videoType to prevent the preview from stretching 100vw
  return {
    id: stream.streamId,
    class: [...stream.connection.data.meetingRoles, stream.videoType || 'camera', stream.hasAudio ? 'has-audio' : 'has-no-audio'],
  }
}

export default function useOpenTokHelper() {
  function connect(partnerID, sessionID, sessionToken) {
    return new Promise((resolve, reject) => {
      disconnect()

      state.session = OT.initSession(partnerID, sessionID)
      state.session?.off()

      state.session?.on('connectionCreated', e => {
        state.connections.push(e.connection)
      })

      state.session?.on('connectionDestroyed', e => {
        const connectionIndex = state.connections.findIndex(c => c.connectionId == e.connection.connectionId)
        state.connections.splice(connectionIndex, 1)
      })

      state.session?.on('sessionConnected', () => {
        state.connected = true
      })

      state.session?.on('sessionDisconnected', () => {
        state.connected = false
        state.streams = []
        state.connections = []
      })

      state.session?.on('sessionReconnected', () => {
        state.connected = true
      })

      state.session?.on('streamCreated', e => {
        try {
          e.stream.connection.data = JSON.parse(e.stream.connection.data)
        } catch (error) {
          e.stream.connection.data = {meetingRoles: ['host']}
        }

        state.streams.push(e.stream)
      })

      state.session?.on('streamDestroyed', e => {
        const streamindex = state.streams.findIndex(s => s.streamId == e.stream.streamId)
        state.streams.splice(streamindex, 1)
      })

      state.session?.connect(sessionToken, error => {
        if (error) {
          onError(error)
          reject(error)
        } else {
          resolve(state.session)
        }
      })
    })
  }

  function disconnect() {
    state.session && state.session?.disconnect()
    state.session = null
  }

  function enableCamera(val) {
    state.publishers.camera.enabled = val
  }

  function enableScreen(val) {
    state.publishers.screen.enabled = val
  }

  function onError(error) {
    reportError(error)
    Sentry.captureException(error)
    state.error = error
  }

  function onPublishCameraError(error) {
    state.publishers.camera.error = error
    onError(error)
  }

  function onPublishScreenError(error) {
    state.publishers.screen.error = error
    onError(error)
  }

  function publishCamera(targetElement, properties) {
    state.publishers.camera.error = null

    return new Promise((resolve, reject) => {
      state.publishers.camera.object = OT.initPublisher(
        targetElement, 
        {
          ...properties, 
          name: state.publishers.camera.name,
          publishAudio: state.publishers.camera.audio,
          publishVideo: state.publishers.camera.video,
        }, 
        error => {
          if (error) {
            onPublishCameraError(error)
            reject(error)
          } else {
            state.session?.publish(state.publishers.camera.object, error => {
              if (error) {
                onPublishCameraError(error)
                reject(error)
              } else {
                resolve(state.publishers.camera.object)
                participantStatusDatabaseRef.value?.update({
                  audio: state.publishers.camera.audio,
                  video: state.publishers.camera.video,
                })
              }
            })
          }
        }
      )
    })
  }

  function publishScreen(targetElement, properties) {
    state.publishers.screen.error = null

    return new Promise((resolve, reject) => {
      state.publishers.screen.object = OT.initPublisher(targetElement, properties, error => {
        if (error) {
          onPublishScreenError(error)
          reject(error)
        } else {
          state.session?.publish(state.publishers.screen.object, error => {
            if (error) {
              onPublishScreenError(error)
              reject(error)
            } else {
              state.publishers.screen.object.on('streamDestroyed', function () {
                toggleScreen(false)
              })

              resolve(state.publishers.camera.object)
            }
          })
        }
      })
    })
  }

  function reportError(error) {
    axios.get('https://connect.pitcher.com/live_support/reportError.php', {
          params: {
            browserString: navigator.userAgent,
            errorMessage: error.name,
            joinID: window.location.href,
          },
        })
        .catch(() => console.warn(`failed to report error: ${error.name}`))
  }

  function setCameraName(name) {
    state.publishers.camera.name = name
  }

  function subscribe(stream, targetElement, { isTalking, ...properties }) {
    return new Promise((resolve, reject) => {
      const subscribeObject = state.session?.subscribe(stream, targetElement, properties, error => {
        if (error) {
          reject(error)
        } else {
          resolve(subscribeObject)
        }
      })

      let activity = null;
      subscribeObject.on('audioLevelUpdated', function(event) {
        const now = Date.now();
        if (event.audioLevel > 0.1) {
          if (!activity) {
            activity = {timestamp: now, talking: false};
          } else if (activity.talking) {
            activity.timestamp = now;
          } else if (now - activity.timestamp > 300) {
            // detected audio activity for more than 1s for the first time.
            activity.talking = true;
            if (isTalking) {
              isTalking.value = true
            }
          }
        } else if (activity && now - activity.timestamp > 3000) {
          // detected low audio activity for more than 3s
          if (activity.talking) {
            if (isTalking) {
              isTalking.value = false
            }
          }
          activity = null;
        }
      });


    })
  }

  function toggleCamera(val) {
    state.publishers.camera.active = val
  }

  function toggleCameraAudio(val) {
    state.publishers.camera.object && state.publishers.camera.object.publishAudio(val)
    state.publishers.camera.audio = val
  }

  function toggleCameraVideo(val) {
    state.publishers.camera.object && state.publishers.camera.object.publishVideo(val)
    state.publishers.camera.video = val

  }

  function toggleScreen(val) {
    if (val && state.streams.filter(s => s.videoType == 'screen').length >= 2) {
      state.error = {name: 'PITCHER_SCREEN_OCCUPIED'}
    } else {
      state.publishers.screen.active = val
    }
  }

  function unpublishCamera() {
    state.publishers.camera.object && state.session?.unpublish(state.publishers.camera.object)
    state.publishers.camera.object = null
  }

  function unpublishScreen() {
    state.publishers.screen.object && state.session?.unpublish(state.publishers.screen.object)
    state.publishers.screen.object = null
  }

  function unsubscribe(stream) {
    state.session?.unsubscribe(stream)
  }

  return {
    ...toRefs(state),
    connect,
    disconnect,
    enableCamera,
    enableScreen,
    publishCamera,
    publishScreen,
    setCameraName,
    streamViews,
    subscribe,
    toggleCamera,
    toggleCameraAudio,
    toggleCameraVideo,
    toggleScreen,
    unpublishCamera,
    unpublishScreen,
    unsubscribe,
  }
}

import Vue from 'vue'
import {meetingsCollection} from '../plugins/firebase'
import {reactive, toRefs} from '@vue/composition-api'

const state = reactive({
  exists: false,
  loading: false,
  publishers: [],
  session: null,
  meeting: null,
  settings: {
  },
})

export default function useMeeting() {
  async function load(id) {
    state.loading = true

    const doc = await meetingsCollection.doc(id).get()

    if (doc.exists) {
      state.exists = true

      state.meeting = doc.data()
      state.publishers = parsePublishers(state.meeting.publishers)

      // live session
      meetingsCollection.doc(id).onSnapshot((doc) => {
        const meeting = doc.data()
        state.session = meeting.sessionID

        Vue.set(state.meeting, 'status', meeting.status)
      })
    }

    state.loading = false
  }

  function parsePublishers(val) {
    let publishers = []

    if (Array.isArray(val)) {
      for (const publisher of val) {
        if (typeof publisher === 'object') {
          publishers.push(publisher)
        } else if (publisher === 'camera') {
          publishers.push({role: 'participant', type: 'camera'})
        } else {
          console.error('invalid publisher', publisher)
        }
      }
    }

    return publishers
  }

  return {
    ...toRefs(state),
    load,
  }
}

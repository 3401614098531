<template>
  <div class="stream-view" ref="targetElement" :class="{ 'stream-view--camera-publisher': id === 'camera-publisher', 'is-talking': isTalking }" v-bind="$attrs">
    <template v-if="id === 'camera-publisher'">
      <div class="error ui active dimmer" v-if="error">
        <div class="ui basic active modal">
          <div class="content">
            <a :href="error.helpLink" class="ui red button" target="_blank">
              <i class="video slash icon"></i>
              {{ $gettext("View help article") }}
            </a>
            <p>
              {{ error.text }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="id === 'screen-publisher'">

      <h1 class="ui inverted header">
          {{ $gettext("You are sharing your screen") }}
        </h1>
      <div class="error ui active dimmer" v-if="error">
        <div class="ui basic active modal">
          <div class="content">
            <i class="yellow exclamation triangle"></i>
            {{ error.text }}
          </div>
          <div class="actions">
            <a
              :href="error.helpLink"
              class="ui red large button"
              target="_blank"
            >
              <i class="video slash icon"></i>
              {{ $gettext("View help article") }}
            </a>
            <div
              @click="retryPublishScreen"
              class="ui large button"
              v-if="error.retry"
            >
              <i class="redo icon"></i>
              {{ $gettext("Retry") }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from "@vue/composition-api";
import useOpenTokHelper from "../use/opentok-helper";

const publishOptions = {
  "camera-publisher": {
    facingMode: "user",
    frameRate: 7,
    height: "100%",
    insertMode: "append",
    resolution: "320x240",
    showControls: true,
    style: {
      buttonDisplayMode: 'off',
      nameDisplayMode: 'on',
      audioLevelDisplayMode: 'auto',
    },
    width: "100%",
  },
  "screen-publisher": {
    frameRate: 7,
    height: "100%",
    insertMode: "append",
    name: "screen",
    publishAudio: false,
    resolution: "1280x720",
    showControls: false,
    videoSource: "screen",
    width: "100%",
  },
};

const streamViewErrors = {
  "camera-publisher": {
    OT_CONSTRAINTS_NOT_SATISFIED: {
      helpLink: "https://pconnect.live/browser",
      text: $gettext(
        "Your browser is not supported. Please use a supported browser."
      ),
    },
    OT_USER_MEDIA_ACCESS_DENIED: {
      helpLink: "https://pconnect.live/perm",
      text: $gettext(
        "Your webcam access is blocked, please change your browser settings."
      ),
    },
  },
  "screen-publisher": {
    OT_SCREEN_SHARING_NOT_SUPPORTED: {
      helpLink: "https://pconnect.live/browser",
      text: $gettext(
        "Your browser is not supported. Please use a supported browser."
      ),
    },
    OT_USER_MEDIA_ACCESS_DENIED: {
      helpLink: "https://pconnect.live/perm",
      retry: true,
      text: $gettext(
        "Your screen access is blocked, please change your browser settings."
      ),
    },
  },
};

export default {
  name: "OTStreamView",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const {
      publishCamera,
      publishScreen,
      streams,
      subscribe,
      unpublishCamera,
      unpublishScreen,
      unsubscribe,
    } = useOpenTokHelper();

    const error = ref(null);
    const isTalking = ref(false)
    const stream = streams.value.find((s) => s.streamId === props.id);
    const targetElement = ref(null);

    async function retryPublishScreen() {
      error.value = null;

      try {
        await publishScreen(targetElement.value, publishOptions[props.id]);
      } catch (e) {
        if (streamViewErrors[props.id] && streamViewErrors[props.id][e.name]) {
          error.value = streamViewErrors[props.id][e.name];
        }
      }
    }

    onMounted(async () => {
      try {
        if (props.id == "camera-publisher") {
          await publishCamera(targetElement.value, publishOptions[props.id]);
        } else if (props.id == "screen-publisher") {
          await publishScreen(targetElement.value, publishOptions[props.id]);
        } else {
          await subscribe(stream, targetElement.value, {
            height: "100%",
            insertMode: "append",
            showControls: stream.videoType === "camera",
            style: {
              buttonDisplayMode: 'on',
              nameDisplayMode: stream.videoType === 'camera' ? 'on' : 'off',
              audioLevelDisplayMode: 'auto',
            },
            width: "100%",
            isTalking,
          });
        }
      } catch (e) {
        if (streamViewErrors[props.id] && streamViewErrors[props.id][e.name]) {
          error.value = streamViewErrors[props.id][e.name];
        }
      }
    });

    onBeforeUnmount(() => {
      if (props.id === "camera-publisher") {
        unpublishCamera();
      } else if (props.id === "screen-publisher") {
        unpublishScreen();
      } else {
        unsubscribe(stream);
      }
    });

    return {
      error,
      retryPublishScreen,
      targetElement,
      isTalking
    };
  },
};
</script>

<style lang="scss" scoped>

.stream-view {
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  ::v-deep {
    .OT_root {
      position: absolute;
    }
  }
}

.error.dimmer {
  z-index: auto;
}

.camera-publisher .error .ui.modal {
  max-width: 100%;
  text-align: center;
  font-size: 1.2em;

  .ui.button {
    width: 100%;
    margin-bottom: 0.5em;
  }
}

.screen-publisher {
  display: grid;
  background-color: black;
  border-radius: 12px;

  > .ui.header {
    font-size: 1.2em;
    margin: auto
  }

  /deep/ .OT_publisher {
    display: none;
  }

  .error .ui.modal {
    font-size: 2em;
    text-align: center;
    font-weight: 300;

    .actions {
      text-align: center;

      .ui.button {
        width: 200px;
      }
    }
  }
}

.stream-view.camera {
  margin-bottom: 10px;
  position: relative;
  transition: transform 0.2s ease-out;
  height: 135px;

  @mixin opentokOverlayText() {
    height: auto;
    font-size: 14px;
    line-height: 1;
  }

  @mixin pseudo-element-icon {
    font-family: 'Icons';
    font-weight: 900;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  ::v-deep {
    .OT_widget-container {
      background: #212121;
    }

    .OT_bar {
      display: none;
    }

    .OT_name {
      display: block;
      opacity: 1;
      position: absolute;
      left: 0;
      bottom: auto;
      top: 0;
      right: auto;
      max-width: 85%;
      padding: 8px;
      border-radius: 0 0 12px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: rgba(black, 0.7);

      @include opentokOverlayText();
    }

    .OT_video-poster {
      background: transparent;

      // Default avatar
      &::after {
        @include pseudo-element-icon();
        content: '\f007'; // user
        font-size: 60px;
      }
    }

    .OT_mute.OT_mode-on + .OT_audio-level-meter > .OT_audio-level-meter__audio-only-img {
      display: none;
    }

    .OT_audio-level-meter__audio-only-img {
      background: transparent;
      top: 0;
      right: 0;
      opacity: 1;

      &::after {
        @include pseudo-element-icon();
        content: '\f130'; // microphone
        font-size: 16px;
        color: white;
        text-shadow: 0 0 3px black;
        margin-top: 4px;
        margin-left: -4px;
      }
    }

    .OT_mute.OT_mode-on {
      background: none;
      border: none;
      text-indent: 0;
      color: transparent;
      bottom: auto;
      right: 0;
      top: 0;
      padding: 8px;
      @include opentokOverlayText();

      &::after {
        @include pseudo-element-icon();
        content: '\f028'; // volume-up
        font-size: 16px;
        color: white;
        text-shadow: 0 0 3px black;
      }

      &.OT_active {
        &::after {
          content: '\f6a9'; // volume-mute
        }
      }
    }

    .OT_audio-level-meter__value {
      background-image: radial-gradient(
          circle,
          rgba(#1976D2, 1) 0%,
          rgba(#1976D2, 0) 100%
      );
    }
  }

  &.has-no-audio ::v-deep .OT_mute.OT_mode-on {
    display: none;
  }

  &.has-no-audio ::v-deep .OT_name::before,
  &.has-audio ::v-deep .OT_name::before {
    font-family: 'Icons';
    display: inline-block;
    margin-right: 4px;
    transition: all 300ms ease-out;
    transition-property: color, opacity;
    width: 16px;
    text-align: center;
  }

  &.has-audio ::v-deep .OT_name::before {
    content: '\f130'; // microphone
    opacity: 0.5;
  }

  &.has-no-audio ::v-deep .OT_name::before {
    content: '\f131'; // microphone-slash
    color: #d64857;
    opacity: 1;
    transform: scaleX(-1);
  }

  &.has-audio.is-talking ::v-deep .OT_name::before {
    color: #64DD17;
    opacity: 1;
  }
}

.stream-view--camera-publisher ::v-deep .OT_name {
  &::before {
    content: 'Me (';
  }
  &::after {
    content: ')';
  }
}
</style>
<template>
  <div class="precall" v-show="meeting">
    <div class="ui centered stackable grid container">
      <div class="row">
        <div class="seven wide computer ten wide mobile column">
          <div class="ui large header" v-if="nameInputDisabled">
            {{ $gettext('Please click the button to join the meeting') }}
            <div class="sub header">
              <p v-translate>When requested, please <strong>authorize access</strong> to your Camera &amp; Microphone.</p>
              <p>{{ $gettext('Your camera will be switched-off by default.') }}</p>
            </div>
          </div>
          <div class="ui large header" v-else>
            {{ $gettext('Please enter your name to join the meeting') }}
            <div class="sub header">
              <p v-translate>When requested, please <strong>authorize access</strong> to your Camera &amp; Microphone.</p>
              <p>{{ $gettext('Your camera will be switched-off by default.') }}</p>
            </div>
          </div>
          <ValidationObserver ref="form" slim v-slot="{ handleSubmit }">
            <form :class="{error: meetingIsFull}" @submit.prevent="handleSubmit(onSubmit)" class="ui huge form">
              <ValidationProvider
                  :rules="{required: nameRequired}"
                  mode="lazy"
                  name="Name"
                  slim
                  v-if="!nameInputDisabled"
                  v-slot="{ errors }"
                  vid="name">
                <div :class="{error: errors.length}" class="field">
                  <label for="name">{{$gettext('Your Name')}}</label>
                  <input
                      :placeholder="$gettext('Enter your name')"
                      id="name"
                      type="text"
                      v-focus
                      v-model="name">
                </div>
              </ValidationProvider>
              <button
                  :class="{loading}"
                  class="ui huge fluid primary button"
                  type="submit">
                {{ $gettext('Join Meeting') }}
              </button>
              <div class="ui error message">
                {{ $gettext("You can't join the meeting because it is full.") }}
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="!testingDisabled">
        <div class="row">
          <div class="seven wide computer ten wide mobile column">
            <div class="ui divider"></div>
          </div>
        </div>
        <div class="row">
          <div class="seven wide computer ten wide mobile column">
            <NetworkTest :connectivity-only="connectivityOnly" :use-connect="!testingLocal"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import isEqual from 'lodash/isEqual'
  import NetworkTest from '../components/NetworkTest'
  import router from '../router'
  import useDoc from '../use/doc'
  import useRef from '../use/ref'
  import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
  import {onMounted, reactive, toRefs} from '@vue/composition-api'
  import {required} from 'vee-validate/dist/rules'

  extend('required', {
    ...required,
    message: '{_field_} is required.'
  });

  export default {
    name: "Precall",
    components: {
      NetworkTest,
      ValidationObserver,
      ValidationProvider
    },
    props: {
      authToken: {
        type: String
      },
      displayName: {
        default: '',
        type: String
      },
      meetingID: {
        default: '',
        type: String
      }
    },
    setup(props) {
      const {
        doc: meeting,
        exists: meetingExists,
        loadById: loadMeeting,
        loading: loadingMeeting,
      } = useDoc('meetings')

      const {
        loadByPath,
        docs: participants
      } = useRef()

      const state = reactive({
        connectivityOnly: false,
        form: null,
        loading: false,
        meetingIsFull: false,
        name: props.displayName,
        nameInputDisabled: false,
        nameRequired: true,
        testingLocal: false,
        testingDisabled: false,
      })

      async function onSubmit() {
        state.loading = true

        if (!isNaN(meeting.value.maxParticipants)) {
          await loadByPath('participants', {meetingID: props.meetingID})

          const availableSeats = meeting.value.maxParticipants - 1
          const occupiedSeats = participants.value.filter(p => !p.host).length

          if (occupiedSeats >= availableSeats) {
            state.loading = false
            state.meetingIsFull = true
            return
          }
        }

        const query = {
          authToken: props.authToken,
          meetingID: props.meetingID,
        }

        if (state.name) {
          query.displayName = state.name
        }

        router.push({name: 'confrence', query})
      }

      onMounted(async () => {
        await loadMeeting(props.meetingID)

        if (!meetingExists.value) {
          router.push({name: 'code'})
          return
        }

        state.connectivityOnly = isEqual(meeting.value.publishers, ['screen'])
        state.nameInputDisabled = meeting.value.participantNameInputDisabled
        state.nameRequired = meeting.value.participantNameRequired
        state.testingDisabled = meeting.value.testingDisabled
        state.testingLocal = meeting.value.testingLocal
      })

      return {
        ...toRefs(state),
        loadingMeeting,
        meeting,
        onSubmit
      }
    }
  }
</script>

<style lang="less" scoped>
  .precall {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 100%;
  }
</style>

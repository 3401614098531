import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import { Integrations } from '@sentry/tracing'
import { Offline as OfflineIntegration } from '@sentry/integrations'
import { appVersion, isCI, isNotDevelopment } from '@/utils/environment'
import { isDevelopment } from '../utils/environment'

console.log('App version:', appVersion)

if (!isCI && isNotDevelopment || isDevelopment) {
  Sentry.init({
    dsn: "https://403ebec440494e0887e298a0e546d677@o21303.ingest.sentry.io/6238710",
    Vue,
    autoSessionTracking: true,
    release: appVersion,
    integrations: [
      new Integrations.BrowserTracing(),
      new OfflineIntegration({
        // limit how many events will be locally saved. Defaults to 30.
        maxStoredEvents: 100,
      }),
    ],
    tracingOptions: {
      trackComponents: true, // Track performance subcomponents of the main component
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}